import React from "react"
import Layout from "../components/layout"
import Card from "../components/card"
import CardLayout from "../components/cardLayout"
import ExternalLink from "../components/externalLink"
import sparkles from "../images/sparkles.svg"
import styles from "./community.module.css"

const Community = () => (
  <Layout>
    <Card>
      <CardLayout headerImageSrc={sparkles}>
        <h1 className={styles.header}>Community</h1>

        <p>
          <strong>What's happening in Livingston?</strong>
        </p>

        <p>
          Livingston High School's Black Student Union has formed addresses to
          the Livingston Police Department and Livingston Public Schools
          regarding how reform can be instituted in our community.{" "}
        </p>

        <h4>Highlights include:</h4>

        <ul>
          <li className={styles.highlight}>
            - Require new police hires to hold a Bachelors degree and re-examine
            the way they are vetted (background checks on potential hateful
            affiliations and/or tendencies)
          </li>

          <li className={styles.highlight}>
            - Integrate rigorous implicit bias training for police and school
            professionals
          </li>

          <li className={styles.highlight}>
            - Teaching culturally- and socially-relevant curriculum
          </li>

          <li className={styles.highlight}>
            - Empathy lessons & starting race conversations in elementary
            schools
          </li>
        </ul>

        <h4>Please Sign And Fill Out This Form:</h4>

        <p className="margin-bottom--xxs">
          <ExternalLink to="https://docs.google.com/forms/d/e/1FAIpQLSfzwDZ2DhQlr5w69fyIBegL9tO4S-2a8Toj0LXsHHpqt5VEAQ/viewform">
            LHS’s Black Student Union’s List of Inquiries and Demands
          </ExternalLink>
        </p>
        <p>
          <ExternalLink to="https://www.instagram.com/livingstonbsu/">
            @livingstonbsu
          </ExternalLink>
        </p>

        <p className="margin-bottom--xxs">
          Accounts of Racism and Microagressions in Livingston:
        </p>
        <p>
          <ExternalLink to="https://www.instagram.com/blackatlivingston/">
            @blackatlivingston
          </ExternalLink>
        </p>

        <p className="margin-bottom--xxs">
          <strong>
            Support Black-owned Businesses Around Livingston: Two Comprehensive
            Lists
          </strong>
        </p>
        <p>
          <ExternalLink to="https://www.nj.com/news/2020/06/a-guide-to-black-owned-businesses-in-nj.html">
            A guide to Black-owned businesses in N.J.
          </ExternalLink>
        </p>
        <p>
          <ExternalLink to="https://www.northjersey.com/story/entertainment/dining/2020/06/04/want-support-black-owned-businesses-here-some-north-jersey/3134718001/">
            Where to support Black-owned businesses in North Jersey
          </ExternalLink>
        </p>
      </CardLayout>
    </Card>
  </Layout>
)

export default Community
